<template>
  <div
    class="plat-adv-container"
    v-lazy:background-image="{src: require('../../../assets/images/bg4.png')}"
  >
    <div class="page-outter">
      <div align="center">
        <img
          class="plat-adv-title"
          v-lazy="require('../../../assets/images/title14.png')"
        />
        <div class="plat-adv-ddd">
          <div
            class="plat-adv-ddd-1"
            align="right"
          >
            <div class="d1">
              <img v-lazy="require('../../../assets/images/app-icon42.png')" />
            </div>
            <span class="sss1">医生端</span>
            <div
              class="d2"
              v-for="(item,index) in tdLefts"
              :key="index"
            >
              <span class="s1">{{item.title}}</span>
              <span class="s2">{{item.desc}}</span>
            </div>
          </div>
          <div
            class="plat-adv-ddd-2"
            align=left
          >
            <div class="d1">
              <img v-lazy="require('../../../assets/images/app-icon43.png')" />
            </div>
            <span class="sss1">用户端</span>
            <div
              class="d2"
              v-for="(item,index) in tdRights"
              :key="index"
            >
              <span class="s1">{{item.title}}</span>
              <span class="s2">{{item.desc}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformAdvantage-ch",
  data() {
    return {
      tdLefts: [
        {
          title: "· 在线接诊",
          desc: "在线沟通，优化时间配置，最大化发挥优质医疗价值",
        },
        {
          title: "· 患者管理",
          desc: "通过在线病例及电子处方，随时跟进患者病情管理",
        },
        {
          title: "· 学术交流",
          desc: "在线交流社区，可发布分享学术论文、病理讨论",
        },
        {
          title: "· 达人运营",
          desc:
            "建立个人主页，通过发表文章、问答等经营私域流量，提升个人影响力",
        },
      ],
      tdRights: [
        {
          title: "· 实时咨询",
          desc: "医疗、用药等专业问题实时在线咨询，脱离时间地域限制；",
        },
        {
          title: "· 健康知识科普",
          desc: "可在平台订阅健康及药品文章、关注专业医生，获取健康知识；",
        },
        {
          title: "· 便捷购药",
          desc: "一站完成药品选购、电子处方、在线支付、就近配送全流程；",
        },
        {
          title: "· 用药管理",
          desc:
            "根据用户健康及用药情况，提供用药提醒、健康监测等科学用药管理服务；",
        },
        {
          title: "· 私人医生",
          desc: "提供私人医生定制服务，为患者提供一对一健康管理服务",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.plat-adv-container {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  width: 100%;
  padding-bottom: 2.4rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.plat-adv-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.plat-adv-ddd {
  display: flex;
  justify-content: center;
  margin-top: 1.6rem;
  .plat-adv-ddd-1 {
    padding-right: 1.3rem;
  }
  .plat-adv-ddd-2 {
    padding-left: 1.3rem;
    border-left: 1px solid #ccc;
  }
  .d2 {
    .s1 {
      display: block;
      font-size: 0.4rem;
      margin-top: 1rem;
      color: #fff;
    }
    .s2 {
      display: block;
      font-size: 0.3rem;
      margin-top: 0.2rem;
      color: #dcdadd;
    }
  }
  .d1 {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: #6aa5ff;
    margin-bottom: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 1.4rem;
    }
  }
  .sss1 {
    display: block;
    font-size: 0.6rem;
    color: #fff;
  }
}
</style>